import React from "react";

function Services() {
  return (
    <>
      <div className="mt-20  md:mt-48">
        <h2 className="text-center text-5xl font-thin font-algerian ">Our Services</h2>
        <div className="border-2 border-[#9c29b2] w-16 m-auto  mt-2 relative"></div>

        <div className="flex flex-col mt-10 md:px-20 p-10 gap-10">
          <div className="flex gap-5 md:flex-row flex-col">
              <div className=" p-5 bg-[#490D59] text-white rounded-xl">
                <img
                  src="https://i.ibb.co/DKt922w/adoption.png"
                  alt="adoption"
                  border="0"
                  className="w-20 mb-4"
                ></img>
                <h2 className="font-semibold text-lg mb-3">Child Psychology</h2>
                <p>
                  Child psychotherapy strengthen emotional support and address
                  psychological challenges for children. We've made a secure and
                  engaging environment for them to freely express themselves and
                  cultivate healthy coping mechanisms.
                </p>
              </div>

              <div className=" p-5 text-white rounded-xl bg-red-400">
                <img
                  src="https://i.ibb.co/KbgHbmF/check-list.png"
                  alt="check-list"
                  border="0"
                  className="w-20 mb-4"
                ></img>
                <h2 className="font-semibold text-lg mb-3">Assessments</h2>
                <p>
                  Assessments in child psychotherapy involve the systematic
                  evaluation of a child's cognitive, emotional, behavioral, and
                  social functioning. With assessments, we offer insights that
                  can guide personalized interventions.
                </p>
              </div>
              <div className=" p-5 text-white rounded-xl bg-orange-500 ">
                <img
                  src="https://i.ibb.co/VmMpT6w/intervention.png"
                  alt="intervention"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">
                  Early intervention
                </h2>
                <p>
                  Early intervention refers to targeted services and support
                  provided to infants and young children who exhibit
                  developmental delays or are at risk of delays. Our
                  intervention programs are designed to support children's
                  growth, especially in their critical early years.
                </p>
              </div>
              <div className=" p-5 text-white rounded-xl bg-pink-600">
                <img
                  src="https://i.ibb.co/Qc86sHJ/speech-therapy.png"
                  alt="speech-therapy"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">Speech Therapy</h2>
                <p>
                  Speech therapy involves the assessment and treatment of
                  communication disorders and difficulties with speech and
                  language. We create engaging therapy sessions to boost their
                  communication skills.
                </p>
              </div>
          </div>

          <div className="flex gap-5 md:flex-row flex-col ">
              <div className=" p-5 bg-green-700 text-white rounded-xl">
                <img
                  src="https://i.ibb.co/vqybZxk/pills.png"
                  alt="pills"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">Dyslexia Therapy</h2>
                <p>
                  Dyslexia therapy is a specialized intervention crafted to
                  assist children with dyslexia, a learning disorder impacting
                  their reading, spelling, and writing abilities. Our therapies
                  are personalized to each child's unique needs.
                </p>
              </div>
              <div className=" p-5 text-white rounded-xl bg-blue-800">
                <img
                  src="https://i.ibb.co/92qypTW/specialization.png"
                  alt="specialization"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">
                  Special Education
                </h2>
                <p>
                  Special education services provide individualized instruction
                  and support for children with developmental or learning
                  disabilities. Our programs are designed to cater to the
                  individualized learning requirements of children with various
                  abilities.
                </p>
              </div>
              <div className=" p-5 text-white rounded-xl  bg-yellow-700 ">
                <img
                  src="https://i.ibb.co/m9L6fWZ/brain.png"
                  alt="brain"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">Brain Gym</h2>
                <p>
                  Brain Gym comprises a set of movements and exercises aimed at
                  promoting brain function and enhancing learning. These fun
                  activities engage children's minds in a playful manner.
                </p>
              </div>
              <div className=" p-5 text-white rounded-xl bg-yellow-500 ">
                <img
                  src="https://i.ibb.co/8K1Vx01/therapy.png"
                  alt="therapy"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">
                  Autism Intervention
                </h2>
                <p>
                  Autism intervention involves a range of therapies and
                  strategies designed to address the challenges faced by
                  individuals with autism spectrum disorder (ASD). We focus on
                  improving communication, social skills, and overall quality of
                  life.
                </p>
              </div>
          </div>

          <div className="flex gap-5 md:flex-row flex-col">
              <div className="p-5 text-white rounded-xl bg-purple-400">
                <img
                  src="https://i.ibb.co/1svpSyc/philosophy.png"
                  alt="philosophy"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">Mindfulness</h2>
                <p>
                  Mindfulness in child psychotherapy involves teaching children
                  to focus on the present moment, cultivating awareness and
                  emotional regulation. Our programs help children manage
                  stress, improve self-awareness, and cultivate emotional
                  balance.
                </p>
              </div>
              <div className="p-5 text-white rounded-xl bg-red-600">
                <img
                  src="https://i.ibb.co/5vP0zSz/counselor.png"
                  alt="counselor"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">
                  Career Counseling
                </h2>
                <p>
                  Career counseling for children involves helping them explore
                  their interests, strengths, and aptitudes to make informed
                  decisions about educational and career paths. We offer
                  counseling services to help them make wise decisions about
                  their career paths.
                </p>
              </div>
              <div className="p-5 text-white rounded-xl bg-slate-800">
                <img
                  src="https://i.ibb.co/wQR1Fkn/government.png"
                  alt="government"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">
                  Remedial Education
                </h2>
                <p>
                  Remedial education is dedicated to offering extra instruction
                  and support to children facing academic challenges. Our
                  remedial education programs target specific learning
                  challenges, helping children catch up and excel academically.
                </p>
              </div>
              <div className="p-5 text-white rounded-xl bg-gray-500">
                <img
                  src="https://i.ibb.co/6F4Ts6F/cognitive.png"
                  alt="cognitive"
                  border="0"
                  className="w-20 mb-4"
                ></img>

                <h2 className="font-semibold text-lg mb-3">
                  Behavior Modification
                </h2>
                <p>
                  Behavior modification is a therapeutic approach that involves
                  systematically changing undesirable behaviors and reinforcing
                  positive ones. Our behavior modification programs focuses on
                  addressing behavioral concerns, enhancing social interactions,
                  and boosting self-esteem in children.
                </p>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
