import React from "react";
import NavbarPurple from "../Components/NavbarPurple";
import Navbar from "../Components/Navbar";
import email from "../img/email.png";
import smartPhone from "../img/smartphone.png";
import clock from "../img/clock.png";
import Foo from "../Components/Foo";

function Contact() {
  return (
    <>
      <NavbarPurple />
      <Navbar />
      <div id="contact" className="relative md:mt-[-100px] mt-[-80px]">
        <img
          src="https://i.ibb.co/NxBjhLQ/contact.jpg"
          alt="contact"
          border="0"
          className="h-[350px] md:h-[450px] w-full object-cover 
            object-top brightness-75"
        />

        <div
          className="flex justify-center items-center flex-col  absolute top-[50%] left-[50%] translate-x-[-50%] 
          translate-y-[-50%]"
        >
          <h2 className=" text-center text-6xl md:text-8xl text-white font-bold w-80 md:w-auto">
            Contact Us
          </h2>
          <h2 className="text-center text-md md:text-lg text-white font-semibold w-80 md:w-auto">
            Montessori Is A Nurturing And Holistic Approach To Learning
          </h2>
        </div>
      </div>

      <div className="mt-10 px-20 py-10">
        <div className="flex flex-col md:flex md:flex-row md:justify-center items-center gap-5">
          <div className="flex flex-col items-center gap-3 border-4 rounded-2xl border-yellow-300 w-80 py-8">
            <div className="border border-black p-6 rounded-full ">
              <img src={smartPhone} alt="phone" className="w-12" />
            </div>
            <h2 className="font-semibold">Phone No.</h2>
            <div className="flex gap-2 items-center ">
              <a
                href="tel: +91 9820453561"
                className="hover:text-red-600 transition-all"
              >
                9820453561
              </a>
            </div>
          </div>

          <div className="flex flex-col items-center gap-3 border-4 rounded-2xl border-yellow-300 w-80 py-8">
            <div className="border border-black p-6 rounded-full ">
              <img src={clock} alt="phone" className="w-12" />
            </div>
            <h2 className="font-semibold">Tuesday to Sunday</h2>
            <div>12PM to 7PM</div>
          </div>
          <div className="flex flex-col items-center gap-3 border-4 rounded-2xl border-yellow-300 w-80 py-8">
            <div className="border border-black p-6 rounded-full ">
              <img src={email} alt="phone" className="w-12" />
            </div>
            <h2 className="font-semibold">Email Address</h2>
            <div className="hover:text-red-600 transition-all">
              <a href="mailto: info@littledots.com">info@littledots.com</a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-20 items-center justify-center py-20">
        <div>
          <div className="w-80 h-80 md:w-96 md:h-96 mt-24 relative m-auto md:mt-10 md:m-0">
            <img
              src="https://i.ibb.co/6gWGSH8/childplay.jpg"
              alt="childplay"
              border="0"
              className="rounded-full"
            ></img>
          </div>
        </div>

        <div className="p-3 ">
          <div>
            <div>
              {" "}
              <h3 className="text-sm tracking-[.1rem] md:text-lg md:tracking-[.3rem] font-baloo font-bold text-[#E03737]">
                HAVE ANY QUESTION ? SO PLEASE
              </h3>{" "}
              <h2 className="text-4xl md:text-6xl font-baloo font-bold">
                Feel Free to Contact !
              </h2>
            </div>
          </div>

          <div className="mt-10  rounded-xl p-5 md:w-[700px]">
            <form className="flex flex-col gap-5">
              <div className="flex flex-col gap-5 md:flex md:flex-row md:gap-20">
                <div className="flex flex-col gap-3">
                  <label for="firstName">
                    First Name{" "}
                    <span className="text-red-500 text-sm">( Required )</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="p-3 rounded-2xl bg-slate-200 md:w-72"
                    required
                  />
                </div>

                <div className="flex flex-col gap-3">
                  <label for="lastName">
                    Last Name
                    <span className="text-red-500 text-sm"> ( Required )</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="p-3 rounded-2xl bg-slate-200  md:w-72 w-auto"
                    required
                  />
                </div>
              </div>

              <div className="flex flex-col gap-5  md:flex md:flex-row md:gap-20">
                <div className="flex flex-col gap-3">
                  <label for="email">
                    Email Address
                    <span className="text-red-500 text-sm"> ( Required )</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="p-3 rounded-2xl bg-slate-200 md:w-72"
                    required
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <label for="Phone">
                    Phone Number
                    <span className="text-red-500 text-sm"> ( Required )</span>
                  </label>
                  <input
                    type="tel"
                    id="Phone"
                    className="p-3 rounded-2xl bg-slate-200 md:w-72"
                    required
                  />
                </div>
              </div>
              <div className="flex flex-col gap-5 md:flex md:flex-row md:gap-20">
                <div className="flex flex-col gap-3">
                  <label for="message">
                    Message
                    <span className="text-red-500 text-sm"> ( Required )</span>
                  </label>
                  <textarea
                    id="message"
                    className="p-3 rounded-2xl bg-slate-200 md:w-[650px] h-72"
                  ></textarea>
                </div>
              </div>

              <div className="flex justify-between items-center flex-wrap gap-8">
                <div>
                  <button className="px-10 py-3 bg-red-600 text-white rounded-3xl font-semibold">
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className=" py-20">
        <div className="flex flex-col gap-10">
          <h2 className="text-4xl md:text-6xl font-baloo font-bold text-center">
            How to Find Us
          </h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.709708745892!2d72.84287967495422!3d19.164180182058676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6518bb59fe1%3A0xf44037d114986799!2sKesarinath%20CHSL!5e0!3m2!1sen!2sin!4v1701658146523!5m2!1sen!2sin"
            height="400"
            allowfullscreen=""
            loading="lazy"
            title="maps"
            referrerpolicy="no-referrer-when-downgrade"
            className="
          xl:w-[1200px] md:w-[700px] lg:w-[800px] w-80 sm:w-[500px]  m-auto rounded-3xl"
          ></iframe>
        </div>
      </div>

      <Foo />
    </>
  );
}

export default Contact;
