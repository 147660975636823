import React from "react";
// BK7-r3rTuXg?si=fTTDcsR2osguzus1&amp Autism Intervention
// buO9uI8E02k?si=sCBX3qqqFISa1GoX Mindfulness
// BbD61DBWRRQ?si=IUfD-U3w0NW7mhHl Career Counseling
// uTVqvTKW294?si=S3wJjceblKDGE3-A Brain Gym
// MohRlBuxkvs?si=T3k-UdZ2kc6K-uHB Early Intervention
// Iupl5khYjzw?si=2Dn-x0NacxWlPXhz Occupation Therapy
function YoutubeVIdeo(Props) {
  return (
    <>
      <div>
        

        <div>
          <div>
            <iframe
              width="380"
              height="200"
              src={`https://www.youtube.com/embed/${Props.link}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div>
            <h2>{Props.name}</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default YoutubeVIdeo;
