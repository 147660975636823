import React from "react";
import circles from "../img/circles.png";
import NavbarPurple from "../Components/NavbarPurple";
import Navbar from "../Components/Navbar";
import Foo from "../Components/Foo";


function Team() {
  return (
    <>
      <NavbarPurple />
      <Navbar />
      <div id="contact" className="relative md:mt-[-100px] mt-[-80px]">
     
       

        <img src="https://i.ibb.co/6m8bpmy/children-s-silhouettes-showing-muscles-sunset.jpg" alt="children-s-silhouettes-showing-muscles-sunset" border="0" className="h-[350px] md:h-[450px] w-full object-cover object-center brightness-75"></img>

        <div
          className="flex justify-center items-center flex-col  absolute top-[50%] left-[50%] translate-x-[-50%] 
          translate-y-[-50%]"
        >
          <h2 className=" text-center text-6xl md:text-8xl text-white font-bold w-80 md:w-auto">
            Our Team
          </h2>
          <h2 className="text-center text-md md:text-lg text-white font-semibold w-80 md:w-auto">
            Montessori Is A Nurturing And Holistic Approach To Learning
          </h2>
        </div>
      </div>

      <div>
        <div className="text-center mt-20">
          <img src={circles} alt="circles" className="w-10 m-auto" />
          <h2 className="text-5xl font-semibold font-baloo mt-4">
            Meet Our Team
          </h2>
        </div>

        <div className="lg:px-24 md:px-16 px-2 sm:px-5 flex flex-col gap-10 my-20">

        <div className="flex justify-center flex-col items-center  gap-16 p-10 rounded-3xl bg-blue-200">
              <img
                src="https://i.ibb.co/P57XvLn/sheela-dang.png"
                alt="sheela-dang"
                border="0"
                className="w-56 rounded-3xl"
              />
            <div>
              <div className="flex flex-col gap-3">
                <h2 className="text-2xl font-bold text-center">
                  Dr. Sheela Dang
                </h2>
                <p className="text-md leading-relaxed font-semibold text-center">
                Sheela Dang is a Mumbai-based psychologist and the founder/director of Sheela Dang Mental Health Care Centre (OPC), Private Limited and Little Dots Child Development Centre. She is a Psychotherapist and a Licensed Psychologist. Sheela has received formal training in Rational Emotive Behavioural Therapy (REBT) from the AEI Institute in the United States. 
                Sheela currently is working on her Ph.D. in Psychology on a research project titled "APPROACH TO PROMOTE RECOVERY FROM TRAUMATIC NARCISSISTIC ABUSE." 
                She is also a member of the American Psychological Association in Washington, DC (USA).
                Sheela has also completed her rehabilitation psychology (PGRDP), RCI (Rehabilitation Counsel India), and (2020-2021) qualifications.
                Sheela is professionally trained and has clinical experience assessing and treating the mental, emotional, social, and behavioral health of infants, toddlers, children, and adolescents. While also comprehending children's and adolescents' basic psychological needs, as well as how their family and other social contexts influence their social and emotional adjustments, developmental processes, and behavioral adaptation.
                Sheela and her team at littledots make it a priority to provide customized solutions to meet each child's unique needs all under one roof. While sheela works on child-related disorders such as Autism Spectrum Disorder (ASD), ADHD (Attention- deficit/hyperactivity disorder), Learning Disability (LD)/Dyslexia, and Intellectual Disability. 
                Littledots not only specializes in child psychology but also in psychometric assessments for children.
                Sheela has over nine years of experience working in the field of psychology, and she has a tremendous amount of passion for her work. In addition, she coordinates workshops and training sessions on all aspects of mental health for medical professionals, paramedics, students, and members of the general public alike. Her many years of experience in psychotherapy and psychological training have provided her with a wealth of knowledge and a diverse set of skills, including psychological testing, psychotherapy, and counseling training.
                </p>
              </div>
            </div>
          </div>


          {/* <div className="flex justify-center flex-col items-center lg:flex lg:flex-row lg:items-center gap-16 p-10 rounded-3xl bg-yellow-100 ">
            <img
              src="https://i.ibb.co/3yqv0WC/drishti.png"
              alt="drishti"
              border="0"
              className="w-56 rounded-3xl"
            />
            <div>
              <div className="flex flex-col gap-3">
                <h2 className="text-2xl font-bold text-center lg:text-left">
                  Drashti R. Shah
                </h2>
                <p className="text-md leading-relaxed font-semibold text-center lg:text-left">
                  She is a psychologist with her Master’s degree from Singhania University. Since the beginning, she has been passionate about children, and this platform has allowed her to pursue both her passion and her career as a child psychologist. She works with children & adolescents with neurodevelopmental disorders with
                  a great deal of love, compassion, affection and assists their parents as well. When it comes to dealing with these children & adolescents, she is really kind, composed and patient.She provides a safe environment for children & adolescents while
                  also assisting them in learning more about their mental, emotional, social, and behavioural issues through a variety of techniques of therapy. She is committed onto promoting healthy development, emotional well-being and empowering children & adolescents to thrive in lives. Our goal is to work collaboratively with families to make a positive andlong-lasting difference in the lives of children & adolescents.
                </p>
              </div>
            </div>
          </div> */}

          {/* <div className="flex justify-center flex-col items-center lg:flex lg:flex-row lg:items-center gap-16 p-10 rounded-3xl  bg-green-100">
            <img
              src="https://i.ibb.co/LDTrQ7N/hetika.png"
              alt="hetika"
              border="0"
              className="w-56 rounded-3xl"
            />
            <div>
              <div className="flex flex-col gap-3">
                <h2 className="text-2xl font-bold text-center lg:text-left">
                  Hetika Kirti Dhakkan
                </h2>
                <p className="text-md leading-relaxed font-semibold text-center lg:text-left">
                  She is a psychologist with her Bachelor’s degree from Mithibai
                  College of Arts, Mumbai and Master’s degree (clinical
                  psychology) from CMR university, Bangalore. She has always
                  enjoyed working with children. And this platform has enabled
                  her to pursue her passion of using psychology as a means to
                  support and mentor kids of all ages and backgrounds in
                  developing the skills they require to grow and create a safe,
                  nurturing environment where they can work through internal
                  conflicts and improve their emotional regulation. She has a
                  calm yet playful nature towards children. She also enjoys
                  collaborating with parents to promote awareness of the
                  challenges children face while growing and deepen their
                  understanding of their kids.
                </p>
              </div>
            </div>
          </div> */}

          {/* <div className="flex justify-center flex-col items-center lg:flex lg:flex-row lg:items-center gap-16 p-10 rounded-3xl bg-red-100">
            <img
              src="https://i.ibb.co/XDsLJbL/Shivangi-1.png"
              alt="shivangi"
              border="0"
              className="w-56 rounded-3xl"
            />
            <div>
              <div className="flex flex-col gap-3">
                <h2 className="text-2xl font-bold text-center lg:text-left">
                  Shivangi Rawat
                </h2>
                <p className="text-md leading-relaxed font-semibold text-center lg:text-left">
                  Miss Shivangi Rawat is a psychologist at Little Dots Child
                  Developmental Centre. She has always had a knack of connecting
                  with children and an unabashed love for supporting children in
                  their journeys of becoming a unique person with a happy and
                  blossoming life. She along with her team sought out to bring
                  the best in every child whilst utilising different approaches
                  for every little one. She has done her Graduation in
                  Psychology and Philosophy and she completed her Post
                  Graduation in Clinical Psychology. She has a passion for
                  helping children and creating distinctive paths to make their
                  learning experience a jolly one. She works in areas like-
                  expressive skills, speech and language processing and
                  emotional regulation for young hearts. For her, it goes beyond
                  the realms of work but more of something she loves. It is in
                  her enthusiasm that dictates her motive to pursue this merry
                  path along with the ardent devotion of her team.
                </p>
              </div>
            </div>
          </div> */}

          <div className="flex justify-center flex-col items-center lg:flex lg:flex-row lg:items-center gap-16 p-10 rounded-3xl bg-purple-100">
            <img
              src="https://i.ibb.co/jZ8zTGp/shrusti.png"
              alt="shrusti"
              border="0"
              className="w-56 rounded-3xl"
            />
            <div>
              <div className="flex flex-col gap-3">
                <h2 className="text-2xl font-bold text-center lg:text-left">
                  Shrusti S Mehta
                </h2>
                <p className="text-md leading-relaxed font-semibold text-center lg:text-left">
                  Shrusti S Mehta is a psychologist with her Bachelor’s degree
                  from St. Wilfred College and her Master’s degree in Psychology
                  from Bir Tikendrajit University. Shrusti has 2 years of
                  experience in Child & Adolescent Psychology and Marital
                  Counselling. In nature, she is a very calm, collected, and
                  easy to understand person. Shrusti strives to assist children
                  and adolescents in overcoming obstacles and discovering their
                  full potential which nurtures their mental and emotional
                  growth. And also enjoys working with them. Having rich
                  experience in handling marital issues, shrusti also guides
                  couples through the complexities of relationships and provides
                  a supportive environment for them to work together overcome
                  their hurdles and to establish a stronger foundation. Shrusti
                  seeks to support adults in exploring their thoughts and
                  feelings, empowering them to make assertive and informed
                  decisions, with a pratical approach and a commitment to
                  encouraging personal growth in every area of their life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Foo />

    </>
  );
}

export default Team;
