import React from "react";
import NavbarPurple from "../Components/NavbarPurple";
import Navbar from "../Components/Navbar";
import greenCheck from "../img/check_green.png";
import img2 from "../img/instagram.png";
import img3 from "../img/linkedin.png";
import circles from "../img/circles.png";
import Foo from "../Components/Foo";

function About() {
  return (
    <>
      <NavbarPurple />
      <Navbar />
      <div className="relative md:mt-[-100px] mt-[-80px]">

      <img src="https://i.ibb.co/yPkM3Wd/about-us.jpg" alt="about-us" border="0" className="h-[350px] md:h-[450px] w-full object-cover object-bottom brightness-75" />
      
        <div
          className="flex justify-center items-center flex-col  absolute top-[50%] left-[50%] translate-x-[-50%] 
          translate-y-[-50%]"
        >
          <h2 className=" text-center text-6xl md:text-8xl text-white font-bold w-80 md:w-auto">
            About Us
          </h2>
          <h2 className="text-center text-md md:text-lg text-white font-semibold w-80 md:w-auto">
            Montessori Is A Nurturing And Holistic Approach To Learning
          </h2>
        </div>
      </div>

      <div className="my-20">
        <div className="flex flex-col items-center justify-between xl:px-20 lg:px-8 lg:flex lg:flex-row lg:items-center">
          <div className="order-2 lg:order-1 flex flex-col gap-5">
            <h2 className="xl:text-5xl lg:text-4xl  text-center lg:text-left font-baloo font-semibold mt-10 text-2xl">
              A Step Towards The Betterment of Your Child.
            </h2>

            <p className="leading-loose text-lg hidden sm:block">
              We are continuously broadening our service portfolio to cater to
              children
              <br />
              of all age groups, aiming to provide meticulous education in an
              engaging
              <br />
              manner. Our objective is to transform the learning experience by:
            </p>

            <p className="leading-loose text-lg sm:hidden  text-center">
              We are continuously broadening our service portfolio to cater to
              children of all age groups, aiming to provide meticulous education
              in an engaging manner. Our objective is to transform the learning
              experience by:
            </p>
            <div className="flex flex-col gap-3 px-3">
              <div className="flex items-center gap-3">
                <div>
                  <img src={greenCheck} alt="check" className="w-6" />
                </div>
                <div>
                  <h3>Offering detailed reports on individual progress.</h3>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <img src={greenCheck} alt="check" className="w-6" />
                </div>
                <div>
                  <h3>
                    Organizing educational field trips and school presentations.
                  </h3>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <img src={greenCheck} alt="check" className="w-6" />
                </div>
                <div>
                  <h3>
                    Providing personalized attention in a small-class
                    environment.
                  </h3>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div>
                  <img src={greenCheck} alt="check" className="w-6" />
                </div>
                <div>
                  <h3>
                    Implementing a structured learning program with after-school
                    care.
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="border border-black p-2 rounded-full order-1 lg:order-2">
            <img
              src="https://i.ibb.co/2Yf30B5/about-1.jpg"
              alt="about-1"
              border="0"
              className="xl:w-[550px] xl:h-[350px] lg:w-[400px] 
              lg:h-[350px] w-80 md:w-[550px] rounded-full"
            />
          </div>
        </div>
      </div>

      <div className="my-20">
        <div className="flex flex-col items-center  xl:px-20 lg:px-8 lg:flex lg:flex-row lg:items-center lg:justify-between ">
          <div className="order-2 lg:order-2 flex flex-col gap-5">
            <h2 className="xl:text-5xl lg:text-4xl  text-center lg:text-left font-baloo font-semibold mt-10 text-2xl">
              Your Child's Best Start In Life
            </h2>

            <p className="leading-loose text-lg hidden sm:block">
              We see through the development and nutrition to the wants and{" "}
              <br /> needs of the students, giving them a viable environment
              where <br /> they can explore their own interests and develop
              their <br /> understanding of the things they love.
            </p>

            <p className="leading-loose px-2 text-lg sm:hidden text-center">
              We see through the development and nutrition to the wants and
              needs of the students, giving them a viable environment where they
              can explore their own interests and develop their understanding of
              the things they love.
            </p>
            <div className="flex gap-5">
              <div className="flex flex-wrap md:gap-10 gap-2 items-center justify-center">
                <div>
                  <h3 className=" font-bold ">SESSIONS : TUESDAY TO SUNDAY</h3>
                </div>
                <div className="flex items-center gap-5">
                  <h3 className="text-[#c20000] font-semibold text-lg">
                    Working Hours :
                  </h3>
                  <span>12PM to 7PM</span>
                </div>
              </div>
            </div>
          </div>

          <div className="border border-black p-2 rounded-full order-1 lg:order-1">
            <img
              src="https://i.ibb.co/Wp2NRQS/about-2.jpg"
              alt="about-1"
              border="0"
              className="xl:w-[550px] xl:h-[350px] lg:w-[400px] 
              lg:h-[350px] w-80 md:w-[550px] rounded-full"
            />
          </div>
        </div>
      </div>

      <div className="bg-purple-200 py-10">
        <div className="text-center ">
          <img src={circles} alt="circles" className="w-10 m-auto" />
          <h2 className="text-5xl font-semibold font-baloo mt-4">
            Qualified Psychologist
          </h2>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-10 mt-20">
          <div className="flex flex-wrap items-center justify-center px-20 gap-10 rounded-3xl bg-white py-10 md:h-[400px]">
            <div>
              <img
                src="https://i.ibb.co/P57XvLn/sheela-dang.png"
                alt="sheela-dang"
                border="0"
                className="w-64 rounded-3xl"
              />
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col gap-3">
                <h2 className="text-3xl font-bold">Dr. Sheela Dang</h2>
                <p className="font-bold text-xl text-[#E03737]">
                  Founder - Littledots
                </p>
                <a href="tel: +91 9820453561" className="font-semibold text-md">+91 9820453561</a>
              </div>

              <div className="flex gap-3 py-4 ">
                <div className="w-12 h-12 rounded-full flex justify-center items-center bg-slate-100">
                  <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
                    <img src={img2} alt="" className="w-5"></img>
                  </a>
                </div>
                <div className="w-12 h-12 rounded-full flex justify-center items-center bg-slate-100">
                  <a href="https://www.linkedin.com/in/sheela-dang-a71887227?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                    <img src={img3} alt="" className="w-5"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col">
            <div className="flex md:gap-8 gap-3">
              <div className="relative">
                <img
                  src="https://i.ibb.co/3yqv0WC/drishti.png"
                  alt="drishti"
                  border="0"
                  className="md:w-52 w-44 rounded-2xl"
                />
                <div
                  className="absolute bottom-3 p-3  bg-purple-500 
                w-[90%] ml-2.5 rounded-3xl"
                >
                  <h2 className="text-center font-semibold text-sm md:textlg">
                    Drashti Rupesh Shah
                  </h2>
                </div>
              </div>
              <div className="relative">
                <img
                  src="https://i.ibb.co/LDTrQ7N/hetika.png"
                  alt="hetika"
                  border="0"
                  className="md:w-52 w-44 rounded-2xl"
                />
                <div
                  className="absolute bottom-3 p-3  bg-purple-500 
                w-[90%] ml-2.5 rounded-3xl"
                >
                  <h2 className="text-center text-sm font-semibold">
                    Hetika Kirti Dhakkan
                  </h2>
                </div>
              </div>
            </div>
            <div className="flex md:gap-8 gap-3 mt-4">
              <div className="relative">
                <img
                  src="https://i.ibb.co/XDsLJbL/Shivangi-1.png"
                  alt="shivangi"
                  border="0"
                  className="md:w-52 w-44  rounded-2xl"
                />
                <div
                  className="absolute bottom-3 p-3  bg-purple-500 
                w-[90%] ml-2.5 rounded-3xl"
                >
                  <h2 className="text-center font-semibold text-sm ">
                    Shivangi Rawat
                  </h2>
                </div>
              </div>
              <div className="relative">
                <img
                  src="https://i.ibb.co/jZ8zTGp/shrusti.png"
                  alt="shrusti"
                  border="0"
                  className="md:w-52 w-44 rounded-2xl"
                />
                <div
                  className="absolute bottom-3 p-3  bg-purple-500 
                w-[90%] ml-2.5 rounded-3xl"
                >
                  <h2 className="text-center font-semibold text-sm ">
                    Shrusti Sanjay Mehta
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <h2 className="text-center text-2xl md:text-3xl font-baloo font-bold">
          Connnect <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==" className="border-2 border-b-black border-t-white border-l-white border-r-white">@littledotsofficial</a> 
        </h2>
        <div className="flex items-center justify-center flex-wrap gap-10 my-10">
          <div className="flex items-center flex-wrap justify-center gap-5">
            <div>
              <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
                <img
                  src="https://i.ibb.co/99PycD1/insta6.jpg"
                  alt="insta6"
                  border="0"
                  className="w-40 rounded-3xl"
                />
              </a>
            </div>
            <div>
              <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
                <img
                  src="https://i.ibb.co/9bYhXnC/insta5.jpg"
                  alt="insta5"
                  border="0"
                  className="w-40 rounded-3xl"
                />
              </a>
            </div>
            <div>
              <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
                <img
                  src="https://i.ibb.co/z88QntX/insta4.jpg"
                  alt="insta4"
                  border="0"
                  className="w-52 rounded-3xl"
                />
              </a>
            </div>
          </div>

          <div className="flex items-center flex-wrap justify-center gap-5">
            <div>
              <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
                <img
                  src="https://i.ibb.co/8DWZ4Xt/contact-img.jpg"
                  alt="contact-img"
                  border="0"
                  className="w-52 h-40 rounded-3xl"
                />
              </a>
            </div>
            <div>
              <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
                <img
                  src="https://i.ibb.co/cbJVqhj/insta3.jpg"
                  alt="insta3"
                  border="0"
                  className="w-40 rounded-3xl"
                />
              </a>
            </div>
            <div>
              <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
                <img
                  src="https://i.ibb.co/XD0YkKj/form-1.jpg"
                  alt="form-1"
                  border="0"
                  className="w-52 h-40 rounded-3xl"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <Foo />
    </>
  );
}

export default About;
