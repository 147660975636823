import React from "react";
import NavbarPurple from "../Components/NavbarPurple";
import Navbar from "../Components/Navbar";
import Foo from "../Components/Foo";

import circles from "../img/circles.png";

function Gallery() {
  return (
    <>
      <NavbarPurple />
      <Navbar />
      <div id="contact" className="relative md:mt-[-100px] mt-[-80px]">
        {/* <img
          src="https://i.ibb.co/NxBjhLQ/contact.jpg"
          alt="contact"
          border="0"
          className="h-[350px] md:h-[450px] w-full object-cover 
            object-top"
        /> */}

        <img
          src="https://i.ibb.co/s654qx5/Gallery.jpg"
          alt="Gallery"
          border="0"
          className="h-[350px] md:h-[450px] w-full object-cover object-center brightness-75"
        />

        <div
          className="flex justify-center items-center flex-col  absolute top-[50%] left-[50%] translate-x-[-50%] 
          translate-y-[-50%]"
        >
          <h2 className=" text-center text-6xl md:text-8xl text-white font-bold w-80 md:w-auto">
            Gallery
          </h2>
          <h2 className="text-center text-md md:text-lg text-white font-semibold w-80 md:w-auto">
            Montessori Is A Nurturing And Holistic Approach To Learning
          </h2>
        </div>
      </div>

      <div className="mt-20">
        <div className="text-center ">
          <img src={circles} alt="circles" className="w-10 m-auto" />
          <h2 className="text-6xl font-semibold font-baloo mt-4">
            Our Gallery
          </h2>
        </div>
        <div className="flex flex-col justify-center items-center gap-10  lg:w-[800px] p-10 m-auto">
          <div className="flex flex-col gap-10 items-center md:flex md:flex-row">
            <div>
              <img
                src="https://i.ibb.co/8DWZ4Xt/contact-img.jpg"
                alt="contact-img"
                border="0"
                className=" rounded-3xl"
              />
            </div>
            <div>
              <img
                src="https://i.ibb.co/Wp2NRQS/about-2.jpg"
                alt="about-2"
                border="0"
                className=" rounded-3xl"
              />
            </div>
          </div>

          <div>
            <div>
              <img
                src="https://i.ibb.co/bFj4Qqd/Gallery.jpg"
                alt="Gallery"
                border="0"
                className="w-full rounded-3xl"
              />
            </div>
          </div>

          <div className="flex flex-col items-center gap-10 md:flex md:flex-row">
            <div>
              <img
                src="https://i.ibb.co/XD0YkKj/form-1.jpg"
                alt="form-1"
                border="0"
                className=" rounded-3xl"
              />
            </div>
            <div>
              <img
                src="https://i.ibb.co/2Yf30B5/about-1.jpg"
                alt="about-1"
                border="0"
                className=" rounded-3xl"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20">
        <div className="text-center ">
          <img src={circles} alt="circles" className="w-10 m-auto" />
          <h2 className="text-6xl font-semibold font-baloo mt-4">Our Clinic</h2>
        </div>
        <div className="flex flex-col justify-center items-center gap-10  lg:w-[800px] p-10 m-auto ">
          <div className="flex flex-col gap-10 items-center md:flex md:flex-row">
            <div>
              <img
                src="https://i.ibb.co/nnZHXsJ/home1.png"
                alt="home1"
                border="0"
                className="rounded-2xl w-[800px]"
              />
            </div>
            <div>
              <img
                src="https://i.ibb.co/RPpBjY3/home2.png"
                alt="home2"
                border="0"
                className="rounded-2xl w-[800px]"
              />
            </div>
          </div>

          <div>
            <div>
              <img
                src="https://i.ibb.co/n3Qyym9/home3.png"
                alt="home3"
                border="0"
                className=" w-[800px] rounded-2xl"
              />
            </div>
          </div>

          <div className="flex flex-col items-center gap-10 md:flex md:flex-row">
            <div>
              <img
                src="https://i.ibb.co/zbVvD3j/home4.png"
                alt="home4"
                border="0"
                className="rounded-2xl w-[800px]"
              />
            </div>
            <div>
              <img
                src="https://i.ibb.co/fx7ghJR/home5.png"
                alt="home5"
                border="0"
                className="rounded-2xl w-[800px]"
              />
            </div>
          </div>
        </div>
      </div>

      <Foo />
    </>
  );
}

export default Gallery;
