import React from "react";
import redCheck from "../img/red_check.png";

function Form() {
  return (
    <>
    
      <div className="flex flex-col md:items-center md:gap-20 gap-10 lg:flex lg:flex-row justify-center bg-gray-100 py-20">
        <div>
          <div className="flex flex-col  items-center md:flex md:flex-row  md:justify-center lg:flex lg:flex-col">
            <div>
              <img
                src="https://i.ibb.co/XD0YkKj/form-1.jpg" 
                alt="form-1"
                border="0"
                className="border-4 border-white w-96 h-52 md:w-96  md:h-56 rounded-3xl"
              />
            </div>
            <div>
              <img
                src="https://i.ibb.co/L5h2BS2/form-2.jpg" 
                alt="form-2"
                border="0"
                className="border-4 border-white w-96 h-52 md:w-96  md:h-56 rounded-3xl"
              />
            </div>
          </div>
        </div>

        <div className="p-3 ">
          <div>
            <div>
              {" "}
              <h2 className="text-4xl md:text-6xl font-baloo font-bold">Appy For Admission</h2>{" "}
            </div>
            <div className="mt-4 flex flex-col gap-1.5 font-lighter text-gray-600">
              <div className="md:flex md:flex-row md:gap-5 flex flex-col gap-1.5">
                <div className="flex items-center gap-3">
                  <img src={redCheck} alt="" />
                  <h4>Assign practice exercises</h4>
                </div>

                <div className="flex items-center gap-3 ">
                  <img src={redCheck} alt="" />
                  <h4>Videos and Articles</h4>
                </div>
              </div>

              <div className="md:flex md:gap-5 md:flex-row flex flex-col gap-1.5">
                <div className="flex items-center gap-3">
                  <img src={redCheck} alt="" />
                  <h4>Track student progress</h4>
                </div>

                <div className="flex items-center gap-3">
                  <img src={redCheck} alt="" />
                  <h4>Join millions of students</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 border-4 border-purple-900 rounded-xl p-5 md:w-[700px]">
            <form className="flex flex-col gap-5">
              <div className="flex flex-col gap-5 md:flex md:flex-row md:gap-20">

                <div className="flex flex-col gap-5">
                  <label for="childName">Child's Name <span className="text-red-500 text-sm">( Required )</span></label>
                  <input type="text" id="childName" className="p-3 rounded-2xl bg-slate-200 md:w-72" required/>
                </div>

                <div className="flex flex-col gap-3">
                  <label for="childDOB">Child's DOB <span className="text-red-500 text-sm">( Required )</span></label>
                  <input type="date" id="childDOB" className="p-3 rounded-2xl bg-slate-200  md:w-72 w-auto" required/>
                </div>
              </div>

              <div className="flex flex-col gap-5  md:flex md:flex-row md:gap-20">
                <div className="flex flex-col gap-3">
                  <label for="ParentName">Parent Name <span className="text-red-500 text-sm">( Required )</span></label>
                  <input type="text" id="ParentName" className="p-3 rounded-2xl bg-slate-200 md:w-72" required/>
                </div>
                <div className="flex flex-col gap-3">
                  <label for="parentPhone">Parent Phone <span className="text-red-500 text-sm">( Required )</span></label>
                  <input type="tel" id="parentPhone" className="p-3 rounded-2xl bg-slate-200 md:w-72" required/>
                </div>
              </div>
              <div className="flex flex-col gap-5 md:flex md:flex-row md:gap-20">
                <div className="flex flex-col gap-3">
                  <label for="parentEmail">Parent Email <span className="text-red-500 text-sm">( Required )</span></label>
                  <input type="email" id="parentEmail" className="p-3 rounded-2xl bg-slate-200 md:w-72" required/>
                </div>
                <div className="flex flex-col gap-3">
                  <label for="services" >Choose Your Service <span className="text-red-500 text-sm">( Required )</span></label>
                  <select className="p-3 rounded-2xl bg-slate-200 md:w-72" required>
                    <option id="services" value="Child Psychology" >
                      Choose Services
                    </option>
                    <option id="services" value="Child Psychology" >
                      Child Psychology
                    </option>

                    <option id="services" value="Assessments">
                      Assessments
                    </option>

                    <option id="services" value="Occupation Therapy">
                      Occupation Therapy
                    </option>

                    <option id="services" value="Early Intervention">
                      Early Intervention
                    </option>

                    <option id="services" value="Speech Therapy">
                      Speech Therapy
                    </option>

                    <option id="services" value="Dyslexia Therapy">
                      Dyslexia Therapy
                    </option>

                    <option id="services" value="Special Education">
                      Special Education
                    </option>

                    <option id="services" value="Brain Gym">
                      Brain Gym
                    </option>

                    <option id="services" value="Autism intervention">
                      Autism intervention
                    </option>

                    <option id="services" value="ADHD Intervention">
                      ADHD Intervention
                    </option>

                    <option id="services" value="Mindfullness">
                      Mindfullness
                    </option>

                    <option id="services" value="Career Counselling">
                      Career Counselling
                    </option>

                    <option id="services" value="Remedial Education">
                      Remedial Education
                    </option>

                    <option id="services" value="Behavior Modification">
                      Behavior Modification
                    </option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between items-center flex-wrap gap-8">
                <div className="flex gap-2 items-center">
                <input type='radio' className="w-3 h-3 bg-slate-500" required/>
                <label className="font-medium">Notify Your child weekly progress</label>
                </div>

                <div>
                  <button className='px-10 py-3 bg-red-600 text-white rounded-3xl font-semibold'>Apply Now</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Form;
