import React from 'react'
import Home from './Pages/Home';
import Contact from "./Pages/Contact";
import About from './Pages/About';
import Team from './Pages/Team';
import Gallery from './Pages/Gallery';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/Contact' element={<Contact/>}></Route>
        <Route path='/About' element={<About/>}></Route>
        <Route path='/Team' element={<Team/>}></Route>
        <Route path='/Gallery' element={<Gallery/>}></Route>
      </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
