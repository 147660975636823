import React from "react";

function NavbarPurple() {
  return (
    <>
      <div className="h-16 bg-[#9C29B2] z-9999 text-center text-white  flex flex-col md:flex-row justify-center items-center 
      xl:gap-[100px] md:gap-[50px] lg:gap-[150px] xl:text-lg lg:text-sm px-10 md:text-[14px]">
        <div className="flex gap-3 md:gap-1 lg:gap-3 justify-center">
          {" "}
          <h3>Phone :</h3>
          <a href="tel: +91 9820453561" className="z-99999 ">
            +91 9820453561
          </a>
        </div>

        <div className="flex justify-center gap-3 md:gap-1 lg:gap-3">
          {" "}
          <h3>email :</h3>
          <a href="mailto: info@littledotscdc.com">info@littledotscdc.com</a>
        </div>
        <div className="hidden md:flex gap-3 md:gap-1 lg:gap-3">
          <h3>Session :</h3>
         <p>Tue to Sun</p>
         <span>-</span>
         <p>12PM to 7PM</p>
        </div>
      </div>
    </>
  );
}

export default NavbarPurple;
