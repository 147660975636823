import React from "react";
import img2 from "../img/instagram.png";
import img3 from "../img/linkedin.png";
import logo from "../img/logo.png";
import email from "../img/email.png";
import phone from "../img/phone.png";
import map from "../img/map.png";


function Foo() {
  return (
    <>
      <div className="bg-[#fde7cd] pb-20">
       
        <img src={logo} alt="Littedots" className="w-96 m-auto"></img>
        <div className="flex flex-wrap items-center mt-[-5rem]  gap-20 justify-center  lg:justify-start">
          <div className="flex flex-col  md:flex md:flex-row md:gap-10">
            <div className="md:ml-5">
              <p className="text-center font-medium md:text-left md:text-2xl w-96 ">
                Children and God's precious gift and we want them to enjoy every
                moment of their childhood
              </p>
              <div className="hidden gap-3 mt-2 md:flex">
                <div className="w-14 h-14 rounded-full border border-black flex justify-center items-center bg-white">
                  <img src={img2} alt="instagram" className="w-6 "></img>
                </div>
                <div className="w-14 h-14 rounded-full border border-black flex justify-center items-center bg-white">
                  <img src={img3} alt="linkedin" className="w-6"></img>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center md:items-start gap-2 mt-5 md:mt-0 ">
                <h2 className="text-xl font-medium">Get In Touch</h2>
                <div className="flex items-center gap-3">
                  <div>Tuesday to Sunday:</div>
                  <div className="font-semibold">12pm to 7pm</div>
                </div>
                <div className="flex items-center gap-3">
                  <div>Monday:</div>
                  <div className="font-semibold">Closed</div>
                </div>
              </div>
              <div className="flex flex-col gap-2  mt-5">
                <div className="flex flex-col items-center md:items-start gap-2 md:flex-row">
                  <img src={email} alt="email" className="w-6"></img>
                  <a href="mailto: info@littledotscdc.com">
                    <p>info@littledotscdc.com</p>
                  </a>
                </div>
                <div className="flex flex-col items-center md:items-start md:flex-row gap-3 mt-3">
                  <img src={phone} alt="phone" className="w-6"></img>
                  <div className="flex gap-2">
                    <a href="tel: +91 9820453561">
                      <p className=""> 9820453561 </p>
                    </a>
                    /
                    <a href="tel: +91 8657459250">
                      <p className=""> 8657459250 </p>
                    </a>
                    /
                    <a href="tel: +91 18002100490">
                      <p className=""> 18002100490 </p>
                    </a>
                  </div>
                </div>
                <div className="flex items-center md:items-start md:flex-row flex-col gap-3 mt-3">
                  <img src={map} alt="address" className="w-6"></img>
                  <p className="text-center md:text-left w-80">
                    Room no. 1 Kesarinath CHSL opp vijaysales, SV road, Goregaon
                    west
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className=" px-2">
            <h2 className="text-2xl font-semibold font-baloo">
              Usefull Services
            </h2>
            <div className="flex gap-14 mt-5 px-3">
              <ul className="flex flex-col list-disc gap-3">
                <li>Child Psychotherapy</li>
                <li>Assessments</li>
                <li>Occupation Therapy</li>
                <li>Early Intervention</li>
                <li>Speech Therapy</li>
                <li>Dyslexia Therapy</li>
                <li>Special Education</li>
              </ul>

              <ul className="flex flex-col list-disc gap-3">
                <li>Brain Gym</li>
                <li>Autism Intervention</li>
                <li>ADHD Intervention</li>
                <li>Mindfulness</li>
                <li>Career Counseling</li>
                <li>Remedial Education</li>
                <li>Behavior Modification</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="py-2 w-full flex flex-col md:flex-row items-center md:justify-between  md:px-10 md:bg-[#ffd600] md:text-black bg-black text-white md:h-20">
        <div className="flex gap-5 py-4 md:hidden">
          <div className="w-9 h-9 rounded-full border border-black flex justify-center items-center bg-white">
            <a href="https://instagram.com/littledotsofficial?utm_source=qr&igshid=OGU0MmVlOWVjOQ==">
              <img src={img2} alt="" className="w-5"></img>
            </a>
          </div>
          <div className="w-9 h-9 rounded-full border border-black flex justify-center items-center bg-white">
            <a href="https://www.linkedin.com/in/sheela-dang-a71887227?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
              <img src={img3} alt="" className="w-5"></img>
            </a>
          </div>
        </div>
        <p className="md:text-lg text-sm font-bold text-center ">
          {" "}
          &copy; 2023 CODERBOX TM. All Rights Reserved
        </p>
        <p className=" text-sm mt-1  hidden md:block">
          Terms & Condition | Privacy Policy | Support
        </p>
      </div>
    </>
  );
}

export default Foo;
