import React from "react";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import Blogs from "../Document/Blogs.pdf"
import Internships from "../Document/Internships.pdf"

function Navbar() {
  
    function closeMenu(){
      document.getElementById("mob-menu").style.marginLeft = "-1300px"
    }

    function openMenu(){
      document.getElementById("mob-menu").style.marginLeft = "0px"
    }

  return (
    <>
      <div className="relative">

 
       <div className="xl:hidden h-screen w-full absolute z-50 mt-[-70px] bg-white ml-[-1300px] transition-all " id="mob-menu"
           onClick={closeMenu}
       > 

       <div className={`navbar `}>
        <div className="flex items-center justify-between">
            <img src={logo} alt="littledots" className="w-56 ml-[-30px]"></img>
            <div className="w-12 h-12 rounded-full flex flex-col gap-2 justify-center items-center bg-[#E03737] xl:hidden mr-4" id="menu-remove">

            <div className="border-white border w-6 rotate-45 "></div>
            <div className="border-white border w-6 rotate-[-45deg] mt-[-9px]"></div>
          </div> 

        </div>
        <div className="p-10">
            <ul className="flex flex-col gap-4 text-2xl font-semibold">
              <li className="border-b pb-3">
              <Link to="/">Home</Link>
              </li>
              <li className="border-b pb-3">
              <Link to="/About">About Us</Link>
              </li>
              <li className="border-b pb-3">
              <Link to="/Team">Team</Link>
              </li>
              <li className="border-b pb-3">
              <Link to="/Gallery">Gallery</Link>
              </li>
              <li className="border-b pb-3">
              <a href={Blogs}>Blogs/Article</a>
              </li>
              <li className="border-b pb-3">
              <a href={Internships}>Internship</a>
              </li>
              <li className="border-b pb-3">
                <Link to="/Contact">Contact Us</Link>
              </li>

            </ul>
        </div>
       </div>
       
       </div>


        <div className="flex items-center justify-between">
          <div className=" ">
            <img
              src={logo}
              alt="littledots"
              className="md:w-80 w-80 
                mt-[-80px] ml-[-50px] md:ml-auto md:mt-[-100px]"
            ></img>
          </div>
          <div>
            <ul className="hidden lg:flex gap-8 mt-[-60px] px-20
            md:px-6  text-lg font-semibold">
              <Link to="/">Home</Link>
              <Link to="/About">About Us</Link>
              <Link to="/Team">Team</Link>
              <Link to="/Gallery">Gallery</Link>
              <li>
              <a href={Blogs}>Blogs/Article</a>
              </li>
              <li>
              <a href={Internships}>Internship</a>
              </li>
              <Link to="/Contact">Contact Us</Link>
            </ul>
          </div>


          <div className="w-12 h-12 rounded-full flex flex-col gap-2 justify-center items-center bg-[#E03737] lg:hidden mt-[-70px] mr-4 toggle" id="menu-open" 
          onClick={openMenu}

          >
          {/* {navbarOpen ? "close" : "open"} */}
            <div className="border-white border w-6 "></div>
            <div className="border-white border w-6 "></div>
            <div className="border-white border w-6 "></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
