import React from "react";

function AboutUs() {
  return (
    <>
    
      <div className="mt-40">
        <div>
        <h2 className="text-center text-5xl md:text-8xl font-algerian font-medium">About Us</h2>
        <div className="border-2 border-[#9c29b2] w-20 m-auto mt-3 relative"></div>
        </div>
        <div className="flex flex-col items-center justify-center lg:flex-row ">
           
            <div className="relative">
             
            <img src="https://i.ibb.co/5hF26kx/i-Phone-8-1-1.png" alt="i-Phone-8-1-1" border="0" className='md:w-[700px] lg:w-auto'></img>

            </div>
            <div className="flex flex-col mt-10 md:mt-0">
                <h2 className="font-baloo font-bold mt-10 md:text-center text-2xl text-center xl:text-5xl md:text-3xl lg:text-left md:px-10 lg:px-0">Every child is unique that's why their way  of handling them shoud be</h2>

                <div className="flex flex-col md:flex-row lg:flex-col gap-10 items-center justify-center mt-20 md:items-start">

                    <div className="flex flex-col justify-center  items-center gap-2  lg:items-start md:text-center">
                       
                        <img src="https://i.ibb.co/jgv1c7g/online.png" alt="online" border="0" className="w-12"></img>

                        <h2 className="text-lg font-semibold">Online Counselling</h2>
                        <p className="w-96 text-center m-auto lg:text-left">We also offer Online counselling to understand your child's unique strengths and challenges. You can get a personalised plan to support your child's growth and development.</p>
                    </div>

                    <div className="flex flex-col text-center items-center gap-2 lg:items-start">
                     
                        <img src="https://i.ibb.co/dbN9fFc/behavioral.png" alt="behavioral" border="0" className="w-12"></img>
                        <h2 className="text-lg font-semibold">Behavioral Interventions</h2>
                        <p className="w-96 text-center lg:text-left"> Our behavior experts work closely with your child to develop effective strategies to manage their behaviors and adapt pattern to develop healthier ones.</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
