import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <div className="relative md:mt-[-100px] mt-[-80px]">
        <img
          src="https://i.ibb.co/p2JXcmh/header.png"
          alt="header"
          border="0"
          className="w-screen h-60 md:h-auto"
        ></img>

        <div
          className="flex justify-center items-center flex-col  absolute top-[50%] left-[50%] translate-x-[-50%] mt-[-20px]
          translate-y-[-50%]"
        >
          <h2 className="font-grand text-center text-2xl md:text-4xl lg:text-6xl text-white font-bold w-80 md:w-auto">
            Empower Your Child with <br />
            <span className="text-[#fff500]">Imagination</span>
          </h2>
          <button className="bg-[#9c29b2] hover:bg-[#E03737] transition-all text-sm md:text-lg px-8 py-3 text-center font-bold rounded-2xl text-white mt-4">
            <Link to="/Contact">Book A Visit</Link>
          </button>
        </div>
      </div>
    </>
  );
}

export default Header;
