import React from "react";
import NavbarPurple from "../Components/NavbarPurple";
import Navbar from "../Components/Navbar";
import Header from "../Components/Header";
import Sec1 from "../Components/Sec1";
import AboutUs from "../Components/AboutUs";
import Services from "../Components/Services";
import ServicesTabSwiper from "../Components/ServicesTabSwiper";
import ServicesSwiper from "../Components/ServicesSwiper";
import DeskSwiper from "../Components/DeskSwiper";
import MobileSwiper from "../Components/MobileSwiper";
import Form from "../Components/Form";
import Foo from "../Components/Foo";
import YoutubeVIdeo from "../Components/YoutubeVIdeo";
import circles from "../img/circles.png";

function Home() {
  return (
    <>
      <div>
        <NavbarPurple />
        <Navbar />
        <Header />
        <Sec1 />
        <AboutUs />

        <div className="md:hidden">
          <Services />
        </div>
        <div className="hidden md:block lg:hidden">
          <ServicesTabSwiper />
        </div>
        <div className="hidden lg:block">
          <ServicesSwiper />
        </div>

        <div>
          <div className="text-center mt-20">
            <img src={circles} alt="circles" className="w-10 m-auto" />
            <h2 className="text-5xl md:text-7xl font-normal font-algerian mt-4">
              Some Videos
            </h2>
          </div>

          <div className="flex flex-col items-center gap-10 mt-10">
          <div className="flex items-center justify-center gap-7 flex-wrap">
            <div>
              <YoutubeVIdeo link="BK7-r3rTuXg?si" />
            </div>

            <div>
              <YoutubeVIdeo link="uTVqvTKW294?si" />
            </div>

            <div>
              <YoutubeVIdeo link="MohRlBuxkvs?si" />
            </div>
          </div>

          <div className="flex items-center justify-center gap-7 flex-wrap">
            <div>
              <YoutubeVIdeo link="Iupl5khYjzw?si" />
            </div>

            <div>
              <YoutubeVIdeo link="buO9uI8E02k?si" />
            </div>

            <div>
              <YoutubeVIdeo link="BbD61DBWRRQ?si" />
            </div>
          </div>
          </div>

          
        </div>

        <div className="hidden md:block">
          <DeskSwiper />
        </div>
        <div className="md:hidden mt-20">
          <MobileSwiper />
        </div>
        <Form />

        <Foo />
      </div>
    </>
  );
}

export default Home;
