import React from "react";
import { Link } from "react-router-dom";

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/css";
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

function swipper() {
  return (
    <>
      <div className="my-10 mt-20 ">
        <h2 className="text-center text-5xl font-normal 
        font-algerian ">
          Meet Your Mentors
        </h2>
        <div className="border-2 border-[#9c29b2] w-20 m-auto mt-2 relative"></div>
      </div>
      <Swiper
        className="h-[550px] mt-10 w-[80vw]"
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={20}
        slidesPerView={2}
        navigation
        pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
      >
        <SwiperSlide className="bg-[#490D59] font-baloo text-lg text-[#fff] rounded-xl flex xl:p-10 p-5 ">
          <div className="flex flex-col">
            <img
              src="https://i.ibb.co/51zL1Fc/sheela.jpg"
              alt="sheela"
              border="0"
              className="lg:w-52 m-auto md:w-40"
            ></img>
            <div>
              <div className="">
                <h2 className="text-center text-2xl font-bold mt-5">
                  Dr. Sheela Dang
                </h2>
                <p className="text-center text-md mt-2">
                  Sheela Dang is a Mumbai-based psychologist and the
                  founder/director of Sheela Dang Mental Health Care Centre
                  (OPC), Private Limited and Little Dots Child Development
                  Centre.<br />{" "}
                  <Link to="/Team" className="text-red-700 font-bold text-xl">
                    Read More...
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        {/* <SwiperSlide className="bg-[#490D59] font-baloo text-lg text-[#fff] rounded-xl flex xl:p-10 p-5">
          <div className="flex flex-col ">
            <img
              src="https://i.ibb.co/3yqv0WC/drishti.png"
              alt="drishti"
              border="0"
              className="lg:w-40 m-auto w-32"
            ></img>
            <div>
              <div className="">
                <h2 className="text-center  text-2xl font-bold mt-5">
                  Drashti Rupesh Shah
                </h2>
                <p className="text-center text-md mt-2">
                  She is a psychologist with her Master’s degree from Singhania
                  University. Since the beginning, she has been passionate about
                  children, and this platform has allowed her to pursue both her
                  passion and her career as a child psychologist.<br/>{" "}
                  <Link to="/Team" className="text-red-700 font-bold text-xl">
                    Read More...
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide> */}

        {/* <SwiperSlide className="w-[40vw] h-[30vw] bg-[#490D59] font-baloo text-lg text-[#fff] rounded-xl xl:p-10 p-5">
          <div className="flex flex-col">
            <img
              src="https://i.ibb.co/LDTrQ7N/hetika.png"
              alt="hetika"
              border="0"
              className="lg:w-40 m-auto w-32"
            ></img>
            <div>
              <div className="">
                <h2 className="text-center  text-2xl font-bold mt-5">
                  Hetika Kirti Dhakkan
                </h2>
                <p className="text-center text-md mt-2">
                  She is a psychologist with her Bachelor’s degree from Mithibai
                  College of Arts, Mumbai and Master’s degree (clinical
                  psychology) from CMR university, Bangalore. She has always
                  enjoyed working with children. <br />{" "}
                  <Link to="/Team" className="text-red-700 font-bold text-xl">
                    Read More...
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide> */}

        {/* <SwiperSlide className="w-[40vw] h-[30vw] bg-[#490D59] font-baloo text-lg text-[#fff] rounded-xl xl:p-10 p-5">
          <div className="flex flex-col">
            <img
              src="https://i.ibb.co/XDsLJbL/Shivangi-1.png"
              alt="shivangi"
              border="0"
              className="lg:w-40 m-auto w-32"
            ></img>


            <div>
              <div className="">
                <h2 className="text-center  text-2xl font-bold mt-5">
                  Shivangi Rawat
                </h2>
                <p className="text-center text-md  leading-[26px]">
                  Miss Shivangi Rawat is a psychologist at Little Dots Child
                  Developmental Centre. She has always had a knack of connecting
                  with children and an unabashed love for supporting children in
                  their journeys of becoming a unique person with a happy and
                  blossoming life. <br />{" "}
                  <Link to="/Team" className="text-red-700 font-bold text-xl">
                    Read More...
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
        
        <SwiperSlide className="w-[40vw] h-[30vw] bg-[#490D59] font-baloo text-lg text-[#fff] rounded-xl xl:p-10 p-5">
          <div className="flex flex-col">
            <img
              src="https://i.ibb.co/jZ8zTGp/shrusti.png"
              alt="shrusti"
              border="0"
              className="lg:w-40 m-auto w-32"
            ></img>
            <div>
              <div className="">
                <h2 className="text-center text-2xl font-bold mt-5">
                  Shrusti Sanjay Mehta
                </h2>
                <p className="text-center text-md leading-[26px] ">
                  Shrusti S Mehta is a psychologist with her Bachelor’s degree
                  from St. Wilfred College and her Master’s degree in Psychology
                  from Bir Tikendrajit University. Shrusti has 2 years of
                  experience in Child & Adolescent Psychology and Marital
                  Counselling. <br />{" "}
                  <Link to="/Team" className="text-red-700 font-bold text-xl">
                    Read More...
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default swipper;
